import React from "react";
import '../CSS/Services.css'
import myImage1 from '../Images/Services/BG Img1.jpg';
import myImage2 from '../Images/Services/BG Img2.jpg';
import myImage3 from '../Images/Services/BG Img3.jpg';


function Services(props) {
    return (
        <div className="header4" id="our-service">
            <div className="container">
                <div className="pt-4 pb-5">
                    <h4 className="fw-bold text-start">AMPLIFYING BUSINESS <br></br>PERFORMANCE THROUGH<br></br> INNOVATION</h4>
                </div>
                <div className="row ">
                    <div className="col-lg-6 pb-3">
                        <div className="text-start"><img className="image" src={myImage1} alt="" /></div>
                    </div>
                    <div className="col-lg-6 pb-3">
                        <div className="text-start">
                            <h5 className="fw-light">INFORMATION TECHNOLOGY</h5>
                            <h3 className="heading fw-bold">Inspiring Solutions, <br></br>Transforming Possibilities</h3>
                            <p className="text-scondary">Innovate. Connect. Transform. Empowering businesses with the<br></br> limitless potential of Information Technology</p>
                            <button className="border border-dark bg-light  p-2">Explore More</button>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 pt-5">
                    <div className="col-lg-6 pb-3 order-2 order-lg-1">
                        <div className="text-start">
                            <h5 className="fw-light">ACCOUNTING</h5>
                            <h3 className="heading fw-bold">Harness the Power of Technology<br></br>for Seamless Accounting Experiences</h3>
                            <p className="text-scondary">Experience the power of numbers with our comprehensive IT <br></br>accounting tools designed to optimize your business's financial<br></br> processes</p>
                            <button className="border border-dark bg-light  p-2">Explore More</button>
                        </div>
                    </div>
                    <div className="col-lg-6 pb-3 order-1 order-lg-2">
                        <div className="text-start"><img className="image" src={myImage2} alt="" /></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 pb-3">
                        <div className="text-start"><img className="image" src={myImage3} alt="" /></div>
                    </div>
                    <div className="col-lg-6 pb-3">
                        <div className="text-start">
                            <h5 className="fw-light">DOCUMENT AUTOMATION</h5>
                            <h3 className="heading fw-bold">Say Goodbye to Manual Work: <br></br>Embrace Seamless Document Automation</h3>
                            <p className="text-scondary">Revolutionize Document Management with Cutting-Edge Automation<br></br> Solutions</p>
                            <button className="border border-dark bg-light  p-2">Explore More</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;

import React from 'react';
import '../CSS/Navbar.css'
import { Link } from 'react-scroll';
import myImage1 from '../Images/Navbar/sys logo.png';


function Navbar(props)  {
  
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light p-0 shadow">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <span><img src={myImage1} width={200} alt="Logo" /></span>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto me-3">
            <li className="nav-item me-3">
              <Link
                  className="nav-link"
                  to="our-service"
                  spy={true}
                  smooth={true}
                  offset={-70} 
                  duration={1500}
                >
                  OUR SERVICE
                </Link>
            </li>
            <li className="nav-item me-3">
                <Link
                  className="nav-link"
                  to="our-career"
                  spy={true}
                  smooth={true}
                  offset={-70} 
                  duration={1500}
                >
                  CAREER
              </Link>
            </li>
            <li className="nav-item me-3">
              <Link
                className="nav-link"
                to="our-team"
                spy={true}
                smooth={true}
                offset={30} 
                duration={1500}
              >
                OUR TEAM
              </Link>
            </li>
            <li className="nav-item me-3">
              <Link
                className="nav-link"
                to="our-contact"
                spy={true}
                smooth={true}
                offset={-70} 
                duration={1500}
              >
                CONTACT US
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
import React from 'react';
import myImage1 from '../Images/Benifits/sec1.img1.webp';
import myImage2 from '../Images/Benifits/sec1.img2.webp';
import myImage3 from '../Images/Benifits/sec1.img3.webp';
import myImage4 from '../Images/Benifits/sec1.img4.webp';
import myImage5 from '../Images/Benifits/sec1.img5.jpg';
import '../CSS/Benifits.css'


function Benifits(props) {
    return (
        <div className="header7" id="our-career">
            <div className="container pt-5 pb-5">
                <div className="row">
                    <div className="col-md-6 sys-head">
                        <div className="sys-hero1">
                            <img className="sys-img1" src={myImage1} alt="" width="100%" />
                            <div className="bottom-gradient-do_more"></div>
                            <div className="info">
                                <h2 className="do_more-grid-title">Experience</h2>
                                <p>
                                <div className="do_more-grid-desc">Design-led transformation. From brand to experience</div><br></br>
                                <div className="hidden-1024">Create unified digital experiences to enhance customer experience and build loyalty</div>
                                </p>
                            </div>
                        </div>    
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 sys-head">
                                <div className="sys-hero1">
                                    <img classNameName="sys-img1" src={myImage2} alt="" width="100%" />
                                    <div className="bottom-gradient-do_more"></div>
                                    <div className="info">
                                        <h2 className="do_more-grid-title">Insight</h2>
                                        <p>
                                        <div className="do_more-grid-desc">Do 100x more.Do it yourseself</div>
                                        <div className="hidden-1024">Build an analytics-driven enterprise to monetize data</div>
                                        </p>
                                    </div>    
                                </div>
                            </div>
                            <div className="col-md-6 sys-head">
                                <div className="sys-hero1">
                                    <img classNameName="sys-img1" src={myImage3} alt="" width="100%" />
                                    <div className="bottom-gradient-do_more"></div>
                                    <div className="info">
                                        <h2 className="do_more-grid-title">Innovate</h2>
                                        <p>
                                        <div className="do_more-grid-desc">Bridge the Physical and digital, with software and platforms</div>
                                        <div className="hidden-1024">Engineer digital-first products and offerings to create new revenue streams</div>
                                        </p>
                                    </div>    
                                </div>
                            </div>
                        </div>
                        <div className="row sys-herohead">
                            <div className="col-md-6 sys-head">
                                <div className="sys-hero1">
                                    <img classNameName="sys-img1" src={myImage4} alt="" width="100%" />
                                    <div className="bottom-gradient-do_more"></div>
                                    <div className="info">
                                        <h2 className="do_more-grid-title">Accelarate</h2>
                                        <p>
                                        <div className="do_more-grid-desc">Keep your core.Keep innovating</div>
                                        <div className="hidden-1024">Find your path to non- disruptive renewal of IT landscapes</div>
                                        </p>
                                    </div>    
                                </div>
                            </div>
                            <div className="col-md-6 sys-head">
                                <div className="sys-hero1">
                                <img classNameName="sys-img1" src={myImage5} alt="" width="100%" />
                                    <div className="bottom-gradient-do_more"></div>
                                    <div className="info">
                                        <h2 className="do_more-grid-title">Insight</h2>
                                        <p>
                                        <div className="do_more-grid-desc">Do 100x more.Do it yourseself</div>
                                        <div className="hidden-1024">Build an analytics-driven enterprise to monetize data</div>
                                        </p>
                                    </div>    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Benifits;
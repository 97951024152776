import './App.css';
import Contact from './Pages/Contact'
import Benifits from './Pages/Benifits';
import Clientservice from './Pages/Clientservice';
import Accesssecurity from './Pages/Accesssecurity';
import Services from './Pages/Services';
import Slidelabel from './Pages/Slidelabel';
import Hovertabs from './Pages/Hovertabs';
import Navbar from './Pages/Navbar';
import Landingpage from './Pages/Landingpage';
import Energizedemployees from './Pages/Energizedemployees';
import ScrollToTopButton from './Pages/ScrollToTopButton';
import CustomNavbar from './Pages/CustomNavbar';

function App() {
  return (
    <div className="App">
      <Navbar/>
      {/* <CustomNavbar/> */}
      <Landingpage/>
      <Energizedemployees/>
      <Hovertabs/>
      <Services/>
      <Slidelabel/>
      <Accesssecurity/>
      <Benifits/>
      <Contact/>
      {/* <ScrollToTopButton /> */}
    </div>
    
  );
}

export default App;

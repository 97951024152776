import React, { useState } from 'react';
import '../CSS/Energizedemployees.css';
import myImage1 from '../Images/Energizedemployees/download.svg';
import myImage2 from '../Images/Energizedemployees/BG Main1.webp';
import myImage3 from '../Images/Energizedemployees/BG Main2.webp';
import myImage4 from '../Images/Energizedemployees/BG Main3.webp';
import myImage5 from '../Images/Energizedemployees/IT.avif';
import myImage6 from '../Images/Energizedemployees/BPO.avif';
import myImage7 from '../Images/Energizedemployees/BDA.avif';

function Energizedemployees(props) {
  const [imageToShow, setImageToShow] = useState(1);

  const handleButtonHover = (imageIndex) => {
    setImageToShow(imageIndex);
  };

  return (
    <div className='header2' id='scrollIntoView'>
      <div className='container pt-5 pb-5'>
        <div className='text-center'>
          <h1>Happy customers. Energized <br></br> employees. That’s impact.</h1>
          <p className='pt-3 fs-6'>Choose one. Or all. You’ll see better customer relationships and happier employees—<br></br>without the bloat, hassle, and expense of typical business software. Plus, you’ll get plenty<br></br> of help from Freddy AI.</p>
        </div>
        <div className="image-container">
          <div className="container">
            <div className='row g-0'>
              <div className='col'></div>
              <div className='col-lg pt-3'>
                <button className={`btn btn-sm border chat-btn ${imageToShow === 1 ? 'active' : ''}`} onMouseEnter={() => handleButtonHover(1)}>
                  IT SERVICE
                </button>
              </div>
              <div className='col-lg pt-3'>
                <button className={`btn btn-sm border chat-btn ${imageToShow === 2 ? 'active' : ''}`} onMouseEnter={() => handleButtonHover(2)}>
                  DOCUMENT AUTOMATION
                </button>
              </div>
              <div className='col-lg pt-3'>
                <button className={`btn btn-sm border chat-btn ${imageToShow === 3 ? 'active' : ''}`} onMouseEnter={() => handleButtonHover(3)}>
                  ACCOUNTING
                </button>
              </div>
              <div className='col'></div>
            </div>
          </div>
          <div className="image-showcase">
            {imageToShow === 1 && (
              <div className="dUxfXO">
                <div className="bDLIOO">
                  <span className='span1'>
                    <span className='span2'>
                      <img className='image1' src={myImage1} alt="Image 1" />
                    </span>
                    <img className='image2' src={myImage5} alt="Image 2" />
                  </span>
                </div>
              </div>
            )}
            {imageToShow === 2 && (
              <div className="dUxfXO">
                <div className="bDLIOO">
                  <span className='span1'>
                    <span className='span2'>
                      <img className='image1' src={myImage1} alt="Image 1" />
                    </span>
                    <img className='image2' src={myImage6} alt="Image 3" />
                  </span>
                </div>
              </div>
            )}
            {imageToShow === 3 && (
              <div className="dUxfXO">
                <div className="bDLIOO">
                  <span className='span1'>
                    <span className='span2'>
                      <img className='image1' src={myImage1} alt="Image 1" />
                    </span>
                    <img className='image2' src={myImage7} alt="Image 4" />
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Energizedemployees;

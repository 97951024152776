import React from 'react';
import '../CSS/contact.css';
import StartFirebase from "./firebaseConfig";
import { ref, set } from "firebase/database";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from "emailjs-com"

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            db: '',
            name: '',
            email: '',
            phone: '',
            message: '',
            btnName:'SEND'
        }
        this.interface = this.interface.bind(this);
    }

    componentDidMount(){
        this.setState({
            db: StartFirebase()
        });
    }

    render() {
        return (
            <div className='pt-5' id="our-contact">
                <div className='pt-5'>
                    <div className='bg-dark p-5'>
                        <ToastContainer autoClose={3000} pauseOnHover={false}/>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-4 text-start pt-3'>
                                    <h6 className='text-white fw-light'>SYSEKAM INNOVATIONS PVT LTD</h6>
                                    <div className='contact-details pt-3'>
                                        <h6 className='pb-2 fw-bold'>ADDRESS:</h6>
                                        <p>115, Kamarajar Salai, Alwartirunagar,<br></br> Valasaravakkam, Chennai,<br></br> Tamil Nadu 600087</p>
                                        <h6 className='fw-bold'>PHONE:</h6>
                                        <p>+91 9080594665</p>
                                    </div>
                                </div>
                                <div className='col-lg-4 text-start pt-3'>
                                    <h6 className='text-white fw-light'>COMPANY</h6>
                                    <div className='contact-details pt-3'><a></a>
                                        <h6 className='pb-2 fw-bold'>About Sysekam</h6>
                                        <h6 className='pb-2 fw-bold'>Leadership</h6>
                                        <h6 className='pb-2 fw-bold'>Services</h6>
                                        <h6 className='pb-2 fw-bold'>Careers</h6>
                                    </div>
                                </div>
                                <div className='col-lg-4 text-start pt-3'>
                                    <h6 className='text-white fw-light'>GET IN TOUCH</h6>                                        
                                    <div className='pt-3'>
                                        <div className='row g-2'>
                                            <div className='col-sm-6 '>
                                                <input className='w-100 border-0 input-field rounded' type='text' placeholder='Name' id='name' name='name' value={this.state.name} onChange={e =>{this.setState({name: e.target.value});}}/>
                                            </div>
                                            <div className='col-sm-6'>
                                                <input className='w-100 border-0 mb-2 input-field rounded' type='email' placeholder='Email' id='email' name='email' value={this.state.email} onChange={e =>{this.setState({email: e.target.value});}}/>
                                            </div>
                                        </div>
                                        <input className='w-100 border-0 mb-2 input-field rounded' type='text' placeholder='Phone' id='phone' name='phone' value={this.state.phone} onChange={e =>{this.setState({phone: e.target.value});}}/><br></br>
                                        <textarea className='w-100 border-0 mb-2 input-field rounded' type='text' placeholder='Message' id='message' name='message' value={this.state.message} onChange={e =>{this.setState({message: e.target.value});}}/>
                                        <button type='button' className='btn btn-sm btn-primary w-100' id='sendBtn' onClick={this.interface}>{this.state.btnName}</button>
                                    </div>                               
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    interface(){   
        const { name, email, phone, message } = this.state;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^\d{10}$/;

        if (!name || !email || !phone || !message) {
          alert('Please fill in all fields');
          return;
        }        
        else if (!emailRegex.test(email)) {
          alert('Please enter a valid email address');
          return;
        }             
        else if (!phoneRegex.test(phone)) {
          alert('Please enter a valid phone number');
          return;
        }
        else{
            this.setState({
                btnName:'SENDING 🚀'
            })
           this.insertData();           
        }       
    }
 
    sendEmail() {            
        const templateParams = {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            message: this.state.message,
            subject:"SYSEKAM WEBSITE ENQUIRY"
        };
      
        emailjs.send('service_wa7a3bj', 'template_ymjf1uc', templateParams, 'BN_8Eb5ZYpKOhCFqT')
        .then((response) => {
            this.setState({
                btnName:'SENT 👍'
            })
            
            toast.success("Our Customer Support Team will Contact you");
            this.resetform();

            setTimeout(() => {
                this.setState({
                  btnName: 'SEND'
                });
            }, 3000);
        })
        .catch((error) => {
            console.log("email error :"+ error)
            toast.error("Error");
        });        
    }

    resetform(){
        this.setState({
            name:"",
            email:"",
            message:"",
            phone:""
        })
    }

    insertData() {
        const { db, name, email, phone, message } = this.state;
      
        set(ref(db, 'Customer/' + name),
        {
          name,
          email,
          phone,
          message
        })
          .then((response) => {
            this.sendEmail()
          })
          .catch(error => {
            console.log("DB error : "+error)
          });
    }
}
export default Contact;

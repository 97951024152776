import React from 'react';
import { Link } from 'react-scroll';
import '../CSS/Landingpage.css';
import sysBackgroundImage1 from '../Images/Landingpage/Landingpage_bg-transformed.png';
import sysBackgroundImage from '../Images/Landingpage/sys-background.png';

const Hero = () => {
  return (
    <div className="hero-container mb-5" style={{ backgroundImage: `url(${sysBackgroundImage1})` }}>
      <div className="container-fluid">
        <div className="row">
          <div className='col-md-1'></div>
          <div className="col-md content">
            <div className="text-start ms-5">
              <p className="text-white landing-content">
                Elevate Your Business with Cutting-Edge IT Solutions
              </p>
            </div>
            <div className="d-flex justify-content-start ms-5">
              <Link
                activeClass="active"
                to="scrollIntoView"
                spy={true}
                smooth={true}
                offset={60}
                duration={900}
              >
                <button className="btn btn-warning btn-lg border mt-3 px-4">
                  Get Started
                </button>
              </Link>
            </div>
          </div>
          <div className='col-lg-6'></div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

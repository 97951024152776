import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";


function StartFirebase() {
    const firebaseConfig = {
        apiKey: process.env.REACT_APP_APIKEY,
        authDomain: "fir-react-b1130.firebaseapp.com",
        databaseURL: "https://fir-react-b1130-default-rtdb.firebaseio.com",
        projectId: "fir-react-b1130",
        storageBucket: "fir-react-b1130.appspot.com",
        messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
        appId: process.env.REACT_APP_APPID
      };
      
      // Initialize Firebase
      const app = initializeApp(firebaseConfig);

      return getDatabase(app);
}

export default StartFirebase;
import React from 'react';
import '../CSS/Slidelabel.css'
import myImage1 from '../Images/Slidelable/sec12.img1.webp';
import myImage2 from '../Images/Slidelable/sec12.img2.webp';
import myImage3 from '../Images/Slidelable/sec12.img3.webp';
import myImage4 from '../Images/Slidelable/sec12.img4.webp'


function Slidelabel(props) {
    return (
        <div class="header5" id="our-team">
        <div class="container pt-5">
            <div className="row">
                <div className="col">
                    <div className="sys-hero2">
                        <div className="careers-img-wrapper">
                            <img className="sys-img1" src={myImage1} alt="" width="100%"/>
                        </div>
                    </div>
                    <div className="career-vido-caption">Life at SYSEKAM</div>
                    <div className="careers-employeespeak p-2">
                        <h5 className="employeespeak-head text-uppercase">What our people say</h5>
                        <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
                            <div className="carousel-inner ">
                              <div className="carousel-item active" data-bs-interval="10000">
                                <img className="sys-img2" src={myImage2} alt="" width="20%"/>
                                <div className="carousel-content">
                                  <h5>First slide label</h5>
                                  <p>Some representative placeholder content for the first slide.</p>
                                </div>
                              </div>
                              <div className="carousel-item" data-bs-interval="2000">
                                <img className="sys-img2" src={myImage3} alt="" width="20%"/>
                                <div className="carousel-content">
                                  <h5>Second slide label</h5>
                                  <p>Some representative placeholder content for the second slide.</p>
                                </div>
                              </div>
                              <div className="carousel-item">
                                <img className="sys-img2" src={myImage4} alt="" width="20%"/>
                                <div className="carousel-content">
                                  <h5>Third slide label</h5>
                                  <p>Some representative placeholder content for the third slide.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="carousel-indicators">
                            <div className='slides-btn'>
                              <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active bg-primary" aria-current="true" aria-label="Slide 1"></button>
                              <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" className="bg-primary" aria-label="Slide 2"></button>
                              <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" className="bg-primary" aria-label="Slide 3"></button>
                            </div>
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    </div>
    );
}

export default Slidelabel;
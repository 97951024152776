import React from 'react';
import '../CSS/Hovertabs.css'
import myImage1 from '../Images/Hovertabs/sec5.img1.png';
import myImage2 from '../Images/Hovertabs/sec5.img2.png';
import myImage3 from '../Images/Hovertabs/sec5.img3.png';





function Hovertabs(props) {
    return (
        <div className='header3'>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="heroheading5 pb-5">
                            <div className="img-container left">               
                                <div className="card-body hero-section">
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="kt_tab_pane_7" role="tabpanel">
                                            <img className="curve" src={myImage1} width="100%"/>
                                        </div>
                            
                                        <div className="tab-pane fade" id="kt_tab_pane_8" role="tabpanel">
                                            <img className="curve" src={myImage2} width="100%"/>
                                        </div>
                            
                                        <div className="tab-pane fade" id="kt_tab_pane_9" role="tabpanel">
                                            <img className="curve" src={myImage3} width="100%"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="navs-tabs">
                            <div className="card border-0 bg-transparent">
                                <div className="card-header-stretch">
                                    <div className="card-toolbar">
                                        <div className="row">
                                            <ul className="nav nav-stretch fs-6">
                                                <div className="col-4">
                                                    <li className="nav-item px2">
                                                        <a className="nav-link text-center text-dark active box5" data-bs-toggle="tab" href="#kt_tab_pane_7">
                                                            <img className="img-curve" src={myImage1} width="85%"/>
                                                        </a>
                                                    </li>        
                                                </div>
                                                <div className="col-4">
                                                    <li className="nav-item px-2">
                                                        <a className="nav-link text-center text-dark box5" data-bs-toggle="tab" href="#kt_tab_pane_8">
                                                            <img className="img-curve" src={myImage2} width="100%"/>
                                                        </a>
                                                    </li>        
                                                </div>
                                                <div className="col-4">
                                                    <li className="nav-item px-2">
                                                        <a className="nav-link text-center text-dark box5" data-bs-toggle="tab" href="#kt_tab_pane_9">
                                                            <img className="img-curve" src={myImage3} width="100%"/>
                                                        </a>
                                                    </li>        
                                                </div>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>    
                        </div>
                    </div>
                    <div className="col-md-6 heading5 text-start">
                        <div className="hero5">
                            Transform your life  <br/> through Technology
                        </div>
                        <div className="herocontant5">
                            Ariel Hocsman lanched anew career in software <br/> development by taking courses on Besnik. What will you <br/> be able to do?
                        </div>
                        <div className="herobutton5">
                            <button type="button" className="button5">Get Started</button>
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    );
}

export default Hovertabs;
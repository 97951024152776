import React from 'react';
import '../CSS/Accesssecurity.css'
import { Icon1, Icon2 } from '../Icons/Iconstore'

function Accesssecurity(props) {
    return (
        <div className='header6'>
            <div className='container pb-5'>
                <h2 className='fw-bold pb-4 pt-5'>Redefining Privilged Access Security</h2>
                <h5 className='pb-5 text-secondary'>With a refreshingly new approach, Secirden offers complete control over <br></br>privileged access, visibility without barriers, and superior access <br></br> governance across cloud, physical, virtal environments.</h5>
                <div className='row'>
                    <div className='col-lg-4'>
                        <div className='access-box pb-5 mb-5 border-4 border-top border-info'>
                            <h5 className='fw-bold pb-3 text-start'>Zero Trust Security Architecture</h5>
                            <div className='text-start'>
                                <div className='row'>
                                    <div className='col-1'><Icon1/></div>
                                    <div className='col-11'><p>Ensure just-in-time,context-<br></br>aware access</p></div>
                                </div>
                                <div className='row'>
                                    <div className='col-1'><Icon1/></div>
                                    <div className='col-11'><p>Protected remote access <br></br>thrugh jump boxes</p></div>
                                </div>
                                <div className='row'>
                                    <div className='col-1'><Icon1/></div>
                                    <div className='col-11'><p>Enforce Multi-Factor<br></br>Authentication</p></div>
                                </div>
                            </div>
                            <a href='' className='ms-5'>Learn More <Icon2/></a>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='access-box pb-5 mb-5 border-4 border-top border-warning'>
                            <h5 className='fw-bold pb-3 text-start'>Least Privilege Enforcement</h5>
                            <div className='text-start'>
                                <div className='row'>
                                    <div className='col-1'><Icon1/></div>
                                    <div className='col-11'><p>Remove admin rights, prevent<br></br>malware</p></div>
                                </div>
                                <div className='row'>
                                    <div className='col-1'><Icon1/></div>
                                    <div className='col-11'><p>Control applications, filter<br></br>commands</p></div>
                                </div>
                                <div className='row'>
                                    <div className='col-1'><Icon1/></div>
                                    <div className='col-11'><p>Achieve just enough access,<br></br>on-the-fly</p></div>
                                </div>
                            </div>
                            <a href='' className='ms-5'>Learn More <Icon2/></a>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='access-box pb-5 mb-5 border-4 border-top border-danger'>
                            <h5 className='fw-bold pb-3 text-start'>Continous Risk Assessment</h5>
                            <div className='text-start'>
                                <div className='row'>
                                    <div className='col-1'><Icon1/></div>
                                    <div className='col-11'><p>Continously audit and moniter<br></br>usage</p></div>
                                </div>
                                <div className='row'>
                                    <div className='col-1'><Icon1/></div>
                                    <div className='col-11'><p>Record and shadow sessions.<br></br>Playback for forensics</p></div>
                                </div>
                                <div className='row'>
                                    <div className='col-1'><Icon1/></div>
                                    <div className='col-11'><p>Identify suspicious activities,,<br></br>prevent attacks.</p></div>
                                </div>                                
                            </div>
                            <a href='' className='ms-5'>Learn More <Icon2/></a>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default Accesssecurity;